import { ReactElement } from 'react';
import { Container, Copyright } from './FooterAuthors.styles';

const FooterAuthors = (): ReactElement => {
  return (
    <Container>
      <Copyright>© {new Date().getFullYear()} Coinpaper</Copyright>
    </Container>
  );
};

export { FooterAuthors };
