import { FC } from 'react';
import { FooterAuthors } from '../FooterAuthors/FooterAuthors';
import { FooterLogo } from '../FooterLogo/FooterLogo';
import { FooterSection } from '../FooterSection/FooterSection';
import { FooterSocials } from '../FooterSocials/FooterSocials';
import { Newsletter } from '../Newsletter/Newsletter';
import { Container, Content, InfoContainer, SectionContainer } from './Footer.styles';
import { FooterProps } from './Footer.types';

export const Footer: FC<FooterProps> = ({ sections, socials, newsletter }) => {
  return (
    <Container newsletter={newsletter}>
      <Content>
        {newsletter && <Newsletter />}
        <FooterLogo />
        <SectionContainer>
          {sections.map((section) => (
            <FooterSection heading={section.heading} key={section.heading} data={section.links} />
          ))}
        </SectionContainer>
        <InfoContainer>
          <FooterSocials socials={socials} />
          <FooterAuthors />
        </InfoContainer>
      </Content>
    </Container>
  );
};
