import tw, { styled } from 'twin.macro';
import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';

export const Container = styled.div<{ newsletter?: boolean }>`
  width: 100%;
  background: ${({ theme }) => theme.colors.title};
  padding: 16px 16px 60px 16px;
  margin-top: ${({ newsletter }) => newsletter && '130px'};
  @media ${MEDIA_QUERIES.laptop} {
    padding-top: 64px;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media ${MEDIA_QUERIES.tablet} {
    ${tw`container mx-auto`};
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  @media ${MEDIA_QUERIES.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
  }
  @media ${MEDIA_QUERIES.desktop} {
    grid-template-columns: repeat(7, 1fr);
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  @media ${MEDIA_QUERIES.laptop} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;
