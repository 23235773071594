import tw, { styled } from 'twin.macro';
import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import CoinpaperIcon from '../../../icons/coinpaperIcon.svg';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 40px;
  @media ${MEDIA_QUERIES.laptop} {
    margin-bottom: 64px;
  }
`;

export const Logo = styled(CoinpaperIcon).attrs({ fill: 'black' })`
  ${tw`inline sm:mr-2 mr-0`}
  @media ${MEDIA_QUERIES.laptop} {
    width: 192px;
  }
`;
