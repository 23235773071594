import { ReactElement } from 'react';
import { FooterSectionProps } from './FooterSection.types';
import { Container, Heading, Link } from './FooterSection.styles';

const FooterSection = ({ heading, data }: FooterSectionProps): ReactElement => {
  return (
    <Container>
      <Heading>{heading}</Heading>
      {data.map((link) => (
        <Link href={link.href} key={link.name}>
          {link.name}
        </Link>
      ))}
    </Container>
  );
};

export { FooterSection };
