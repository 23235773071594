import styled, { css } from 'styled-components';

import Cmc from '@components/icons/cmcIcon.svg';
import Copied from '@components/icons/copiedIcon.svg';
import CopiedWhite from '@components/icons/copiedIconWhite.svg';
import Discord from '@components/icons/discordIcon.svg';
import Facebook from '@components/icons/facebookIcon.svg';
import Instagram from '@components/icons/instagramIcon.svg';
import Linkedin from '@components/icons/linkedinIcon.svg';
import RSSFeed from '@components/icons/rssFeedIcon.svg';
import Telegram from '@components/icons/telegramIcon.svg';
import Twitter from '@components/icons/twitterIcon.svg';
import X from '@components/icons/xIcon.svg';

import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import tw from 'twin.macro';

const sharedIconStyle = css`
  ${tw`lg:hover:opacity-50`}
  @media ${MEDIA_QUERIES.laptop} {
    transform: scale(0.8);
  }
`;

export const Container = styled.div`
  position: relative;
  display: inline-grid;
  color: white;
  grid-template-columns: repeat(6, 45px);
  a {
    grid-column: span 2;
    &:nth-child(4) {
      grid-column: 2 / span 2;
    }
  }
  @media ${MEDIA_QUERIES.mobileM} {
    grid-template-columns: repeat(6, 56px);
  }
  @media ${MEDIA_QUERIES.laptop} {
    grid-template-columns: repeat(7, 40px);
    a {
      grid-column: span 1;
      &:nth-child(4) {
        grid-column: auto;
      }
    }
  }
`;

export const TwitterIcon = styled(Twitter)`
  ${sharedIconStyle}
`;

export const XIcon = styled(X)`
  ${sharedIconStyle}
`;

export const DiscordIcon = styled(Discord)`
  ${sharedIconStyle}
`;

export const TelegramIcon = styled(Telegram)`
  ${sharedIconStyle}
`;

export const InstagramIcon = styled(Instagram)`
  ${sharedIconStyle}
`;

export const FacebookIcon = styled(Facebook)`
  ${sharedIconStyle}
`;

export const LinkedinIcon = styled(Linkedin)`
  ${sharedIconStyle}
`;

export const CmcIcon = styled(Cmc)`
  ${sharedIconStyle}
`;

export const RSSFeedIcon = styled(RSSFeed)`
  ${sharedIconStyle}
`;

export const CopiedIcon = styled(CopiedWhite)`
  ${sharedIconStyle}
`;

export const CopiedIconDesktop = styled(Copied)`
  ${tw`mr-2 inline`}

  path {
    ${tw`text-white stroke-current`}
  }
`;

export const RSSFeedContainer = styled.div`
  ${tw`py-3 lg:py-0 flex justify-center items-center col-span-full lg:col-auto gap-x-2.5 border border-white cursor-pointer`}
`;

export const CopyInfo = styled.span`
  ${tw`text-base lg:text-xs font-medium lg:font-semibold text-left lg:text-right lg:col-span-full lg:mt-2 lg:absolute`}

  @media ${MEDIA_QUERIES.laptop} {
    left: 0;
    right: 0;
    bottom: -20px;
  }
`;
