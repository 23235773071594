import styled from 'styled-components';
import { Input } from '@components/common/Input';
import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import MobileBackground from './images/mobileBackground.webp';

export const Container = styled.div`
  display: inline-block;
  background: url(${MobileBackground.src});
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 16px;
  border: ${({ theme }) => `3px solid ${theme.colors.background}`};
  position: relative;
  margin: -130px auto 40px auto;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border: ${({ theme }) => `2px solid ${theme.colors.title}`};
    pointer-events: none;
  }
  @media ${MEDIA_QUERIES.laptop} {
    margin-top: -160px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding: 32px 40px;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  @media ${MEDIA_QUERIES.desktop} {
    margin-top: -180px;
    max-width: 1376px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Heading = styled.h3`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.title};
  @media ${MEDIA_QUERIES.desktop} {
    font-family: ${({ theme }) => theme.typography.heading.fontFamily};
    color: ${({ theme }) => theme.colors.title};
    font-weight: ${({ theme }) => theme.typography.heading.fontWeight};
    font-size: 2rem;
    line-height: 120%;
  }
`;

export const Subheading = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.body};
  margin: 8px 0;
  @media ${MEDIA_QUERIES.desktop} {
    font-size: ${({ theme }) => theme.typography.paragraph.fontSize.large.pc};
    line-height: ${({ theme }) => theme.typography.paragraph.lineHeight.large};
  }
`;

export const StyledInput = styled(Input)`
  &&& input {
    background: transparent;
  }
`;

export const Caption = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.body};
  @media ${MEDIA_QUERIES.desktop} {
    font-size: 0.75rem;
    line-height: 1.1875rem;
    margin-top: 8px;
  }
`;

export const Link = styled.a`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  text-decoration: underline;
`;
