import styled from 'styled-components';

import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media ${MEDIA_QUERIES.laptop} {
    align-items: flex-start;
  }
`;

export const Copyright = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.background};
  text-align: center;
  margin-bottom: 12px;
  margin-top: 40px;
  @media ${MEDIA_QUERIES.laptop} {
    margin-top: 0;
  }
`;

export const Authors = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 0.625rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.label};
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.background};
`;
