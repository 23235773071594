import { ReactElement } from 'react';
import { Container, Logo } from './FooterLogo.styles';

const FooterLogo = (): ReactElement => {
  return (
    <Container>
      <Logo />
    </Container>
  );
};

export { FooterLogo };
