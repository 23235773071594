import { ReactElement } from 'react';
import { Field } from 'react-final-form';
import { InputProps } from './Input.types';
import { Container, InputField, ErrorMessage, Caption, Append, FieldWrapper } from './styles';

export const Input = ({
  type,
  placeholder,
  errorMessage,
  caption,
  appendClick,
  change,
  disabled,
  append,
  className,
}: InputProps): ReactElement => {
  const handleAppendClick = () => {
    if (!disabled && appendClick) {
      appendClick();
    }
  };
  return (
    <Container>
      <FieldWrapper className={className}>
        <InputField
          placeholder={placeholder}
          type={type}
          onChange={(e) => change(e.target.value)}
          error={errorMessage}
          disabled={disabled}
        />
        <Append
          onClick={() => handleAppendClick()}
          hasClickEvent={appendClick && true}
          disabled={disabled}
        >
          {append}
        </Append>
      </FieldWrapper>
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <Caption>{caption}</Caption>
    </Container>
  );
};

Input.defaultProps = {
  disabled: false,
};

Input.defaultProps = {
  disabled: false,
};

export const FinalInput = ({ name, ...props }: InputProps): ReactElement => {
  return <Field name={name}>{({ input }) => <Input name={name} {...props} {...input} />}</Field>;
};
