import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 32px;
`;

export const Heading = styled.p`
  position: relative;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.background};
  margin-bottom: 21px;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.colors.background};
    bottom: -8px;
  }
`;

export const Link = styled.a`
  font-family: 'Inter', sans-serif;
  display: block;
  margin-bottom: 8px;
  font-size: 0.875rem;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.background};
`;
