import { newsletter } from '@api/newsletter';
import ArrowRight from '@components/icons/arrowRightIcon.svg';
import { Label } from '@student-coin/coinpaper-components';
import { buildPrivacyPolicyPageLink } from '@utils/link';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { ReactElement, useState } from 'react';
import { useMutation } from 'react-query';
import { Container, Heading, Link, StyledInput, Subheading } from './Newsletter.styles';
import { NewsletterProps } from './Newsletter.types';

const Newsletter = ({ headingKey = 'newsletter:heading' }: NewsletterProps): ReactElement => {
  const [email, setEmail] = useState<string>(null);
  const { mutate: submit, isLoading, isSuccess, error } = useMutation(newsletter);

  const handleFormSubmit = async () => {
    if (email?.indexOf('@') > -1) {
      submit(email);
    }
  };

  const { t } = useTranslation('newsletter');

  return (
    <Container>
      <div>
        <Heading>
          <Trans i18nKey={headingKey} components={[<br />]} />
        </Heading>
        <Subheading>
          <Trans i18nKey="newsletter:text" components={[<br />]} />
        </Subheading>
      </div>
      <div>
        <StyledInput
          append={<ArrowRight />}
          placeholder="Your email"
          type="email"
          change={setEmail}
          disabled={isLoading}
          appendClick={handleFormSubmit}
          errorMessage={(error as Error)?.message}
          caption={
            <>
              {isSuccess && <Label>{t('success')}</Label>}
              <Trans
                i18nKey="newsletter:policy"
                components={[<Link href={buildPrivacyPolicyPageLink()} />]}
              />
            </>
          }
        />
      </div>
    </Container>
  );
};

export { Newsletter };
