import { ReactElement } from 'react';
import { SocialItem, SocialItemOverlay } from './FooterSocialLink.styles';
import { FooterSocialLinkProps } from './FooterSocialLink.types';

const FooterSocialLink = ({ link, icon }: FooterSocialLinkProps): ReactElement => {
  return (
    <SocialItem href={link}>
      <SocialItemOverlay>{icon}</SocialItemOverlay>
    </SocialItem>
  );
};

export { FooterSocialLink };
