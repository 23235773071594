import { ReactElement } from 'react';
import { MEDIA_QUERIES, useMediaQuery } from '@student-coin/coinpaper-components';
import { useCopy } from '@hooks/useCopy';
import { buildRSSFeedLink } from '@utils/link';
import useTranslation from 'next-translate/useTranslation';
import {
  CopiedIcon,
  CopiedIconDesktop,
  CopyInfo,
  RSSFeedContainer,
  RSSFeedIcon,
} from './FooterSocials.styles';

const feedLink = buildRSSFeedLink();

export const RSSFeedLink = (): ReactElement => {
  const { t } = useTranslation('common');
  const isLaptopWide = useMediaQuery(MEDIA_QUERIES.laptop);
  const [copiedText, copy] = useCopy();
  const onRSSClick = () => copy(feedLink);

  const copyRSSLinkMessage = t('copy-rss-feed-link');
  const linkCopiedMessage = t('link-copied');

  return (
    <>
      <RSSFeedContainer onClick={onRSSClick}>
        {!isLaptopWide && copiedText ? <CopiedIcon /> : <RSSFeedIcon />}
        {!isLaptopWide && (
          <CopyInfo>{copiedText ? linkCopiedMessage : copyRSSLinkMessage}</CopyInfo>
        )}
      </RSSFeedContainer>
      {isLaptopWide && copiedText && (
        <CopyInfo>
          <CopiedIconDesktop />
          {linkCopiedMessage}
        </CopyInfo>
      )}
    </>
  );
};
