import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  max-width: 600px;
`;

type InputFieldProps = {
  error?: string;
};

export const InputField = styled.input<InputFieldProps>`
  color: ${({ error, theme }) => (error ? theme.colors.warning : theme.colors.title)};
  font-weight: ${({ theme }) => theme.typography.paragraph.fontWeight.medium};
  font-size: ${({ theme }) => theme.typography.paragraph.fontSize.medium.pc};
  line-height: 1.0625rem;
  padding: 1rem;
  border: ${({ theme }) => `2px solid ${theme.colors.title}`};
  box-sizing: border-box;
  outline: none;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.title};
    opacity: 1;
  }

  &:focus {
    &::placeholder {
      color: ${({ theme }) => theme.colors.menu};
    }
  }

  &:disabled {
    background-color: transparent;
    opacity: 0.3;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.warning};
  font-size: ${({ theme }) => theme.typography.paragraph.fontSize.small.mobile};
  font-weight: ${({ theme }) => theme.typography.paragraph.fontWeight.medium};
  line-height: 1rem;
  margin: 0.25rem 0;
`;

export const Caption = styled.span`
  font-weight: ${({ theme }) => theme.typography.paragraph.fontWeight.medium};
  font-size: ${({ theme }) => theme.typography.paragraph.fontSize.small.pc};
  line-height: 1.2rem;
  color: ${({ theme }) => theme.colors.body};
  margin: 0;
`;

type AppendIconProps = {
  disabled?: boolean;
  hasClickEvent?: boolean;
};

export const Append = styled.div<AppendIconProps>`
  width: 1.5rem;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: ${(props) => (props.disabled || !props.hasClickEvent ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

export const FieldWrapper = styled.div`
  position: relative;
`;
