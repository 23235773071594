import { ReactElement } from 'react';
import { FooterSocialLink } from '../FooterSocialLink/FooterSocialLink';
import {
  CmcIcon,
  Container,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TelegramIcon,
  XIcon,
} from './FooterSocials.styles';
import { FooterSocialsProps } from './FooterSocials.types';
import { RSSFeedLink } from './RSSFeedLink';

const FooterSocials = ({ socials }: FooterSocialsProps): ReactElement => {
  return (
    <Container>
      <FooterSocialLink link={socials.twitter} icon={<XIcon />} />
      <FooterSocialLink link={socials.telegram} icon={<TelegramIcon />} />
      <FooterSocialLink link={socials.cmc} icon={<CmcIcon />} />
      <FooterSocialLink link={socials.facebook} icon={<FacebookIcon />} />
      <FooterSocialLink link={socials.instagram} icon={<InstagramIcon />} />
      <FooterSocialLink link={socials.linkedin} icon={<LinkedinIcon />} />
      <RSSFeedLink />
    </Container>
  );
};

export { FooterSocials };
